import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import CircularProgress from '../CircularProgress';

import s from './CircularProgressLoader.css';

const CircularProgressLoader = ({
  circularProgressProps,
  rootClassName,
  rootStyle,
}) => (
  <div className={classNames(s.loadingWrapper, rootClassName)}>
    <CircularProgress styles={rootStyle} {...circularProgressProps} />
  </div>
);

CircularProgressLoader.propTypes = {
  rootClassName: PropTypes.string,
  rootStyle: PropTypes.object, // eslint-disable-line
  circularProgressProps: PropTypes.shape({
    size: PropTypes.number,
    thickness: PropTypes.number,
  }),
};

CircularProgressLoader.defaultProps = {
  rootClassName: '',
  rootStyle: {},
  circularProgressProps: {
    size: 100,
  },
};

export default withStyles(s)(CircularProgressLoader);
