import React from 'react';
import { Icon } from './index';

const SkholeIcon02 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <path
      className="st1"
      d="M27.9,3.3c-3.1,0-6.1,1.5-7.9,4c-1.8-2.5-4.7-4-7.9-4c-5.4,0-9.9,4.4-9.9,9.9c0,12.8,16.4,22.9,17.1,23.3
  c0.2,0.1,0.4,0.2,0.7,0.2s0.5-0.1,0.7-0.2c0.7-0.4,17.1-10.6,17.1-23.3C37.8,7.7,33.3,3.3,27.9,3.3z"
    />
  </svg>,
);

export default SkholeIcon02;
