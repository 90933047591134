import React from 'react';
import { Icon } from './index';

const SkholeIcon03 = Icon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 40 40"
    style={{ enableBackground: 'new 0 0 40 40' }}
    xmlSpace="preserve"
  >
    <style type="text/css" />
    <path
      className="st1"
      d="M20,36.7c-0.2,0-0.5-0.1-0.7-0.2C18.7,36.1,2.2,25.9,2.2,13.2c0-5.4,4.4-9.9,9.9-9.9c3.1,0,6.1,1.5,7.9,4
  c1.8-2.5,4.7-4,7.9-4c5.4,0,9.9,4.4,9.9,9.9c0,12.8-16.4,22.9-17.1,23.3C20.5,36.6,20.2,36.7,20,36.7z M12.1,5.8
  c-4.1,0-7.4,3.3-7.4,7.4C4.8,23.2,17,32,20,33.9c3-2,15.3-10.7,15.3-20.8c0-4.1-3.3-7.4-7.4-7.4c-2.9,0-5.6,1.7-6.7,4.4
  C20.9,10.7,20.5,11,20,11s-0.9-0.3-1.1-0.8C17.7,7.6,15,5.8,12.1,5.8z"
    />
  </svg>,
);

export default SkholeIcon03;
