import moment from 'moment';

export const isSameDay = (startDate, endDate) =>
  endDate && moment(startDate).isSame(moment(endDate), 'day');

export default function formatLivestreamDate(startDate, endDate, showOnlyDay) {
  if (!endDate) {
    return moment(startDate).format('D.M.YYYY');
  }

  if (showOnlyDay) {
    return {
      start: moment(startDate).format('D.M.YYYY'),
      end: moment(endDate).format('D.M.YYYY'),
    };
  }

  return {
    start: moment(startDate).format('D.M.YYYY HH:mm'),
    end: moment(endDate).format('D.M.YYYY HH:mm'),
  };
}
